<script setup lang="ts">
import { Button, Popconfirm, Space } from 'ant-design-vue'
import { useAbilityStore } from './useState'
import AbilityPropertyUpdate from './ability-update.vue'
import { deleteDeviceAbilityApi } from '@/api/device-ability'

const props = withDefaults(defineProps<{ services: any[] }>(), { services: () => [] })
const { driverInstances, refreshDeviceAbilityies } = useAbilityStore()!
const modalVisible = ref(false)
const choosedDeviceAbility = ref()
const searchWords = ref()

const state = reactive<TableState>({
  data: [],
  columns: [
    { title: '服务名称', dataIndex: 'abilityName' },
    { title: '服务编号', dataIndex: 'abilityCode' },
    { title: '驱动实例', dataIndex: 'driverInstanceId', customRender({ record }) {
      const instance = driverInstances.value.find((item: any) => item.id === record.driverInstanceId)
      return instance?.driverInstanceName ?? ''
    } },
    { title: '备注', dataIndex: 'description' },
    { title: '操作', customRender({ record }) {
      return h(Space, { direction: 'horizontal', wrap: false }, () => [
        h(Button, { type: 'link', size: 'small', onClick: () => {
          onEdit(record)
        } }, () => '修改'),
        h(
          Popconfirm,
          { title: '确定删除？', onConfirm: async () => {
            await deleteDeviceAbilityApi(record.id)
            await refreshDeviceAbilityies()
          } },
          () => h(
            Button,
            { danger: true, type: 'link', size: 'small' },
            () => '删除',
          ),
        ),
      ])
    } },
  ],
  config: {
    total: 0,
    loading: false,
    isBorder: false,
    isSerialNo: true,
    isSelection: true,
    isOperate: true,
  },
  pagination: false,
})

watch(() => props.services, () => {
  state.data = props.services
}, { immediate: true })

function onEdit(deviceAbility: any) {
  modalVisible.value = true
  choosedDeviceAbility.value = deviceAbility
}

function onConfirm() {}
function onSearch() {
  if (searchWords.value === '' || searchWords.value === undefined)
    state.data = props.services
  state.data = props.services.filter(item => item.abilityName.includes(searchWords.value))
}
</script>

<template>
  <flow-hidden-container>
    <table-layout>
      <template #headerRight>
        <a-space>
          <a-input v-model:value="searchWords" placeholder="请输入服务名称" allow-clear />
          <a-button type="primary" @click="onSearch">
            搜索
          </a-button>
        </a-space>
      </template>
      <template #content>
        <base-table v-bind="state" />
      </template>
    </table-layout>
  </flow-hidden-container>

  <AbilityPropertyUpdate v-model:open="modalVisible" :device-ability="choosedDeviceAbility" @confirm="onConfirm" />
</template>

<style lang="scss" scoped>

</style>
