<script setup lang="ts">
import 'vue-json-pretty/lib/styles.css'
import { PlusOutlined } from '@ant-design/icons-vue'
import AbilityEvent from './ability-event.vue'
import AbilityProfile from './ability-profile.vue'
import AbilityProperty from './ability-property.vue'
import AbilityService from './ability-service.vue'
import { useAbilityStore } from './useState'
import AbilityPropertyUpdate from './ability-update.vue'

const open = defineModel<boolean>('open')

const { device, properties, services, events, profiles, refreshDeviceAbilityies } = useAbilityStore()!

const modalVisible = ref(false)
const activeKey = ref('1')

function back() {
  open.value = false
}

watch(open, async (newOpen) => {
  if (newOpen) {
    activeKey.value = '1'
    await refreshDeviceAbilityies()
  }
})

function onAddAbility() {
  modalVisible.value = true
}

function onConfirm() {}
</script>

<template>
  <div v-if="open" pos-absolute top-0 bottom-0 left-0 right-0 overflow-hidden flex flex-col style="background-color: #f0f2f5;">
    <content-header :title="device?.deviceName ?? ''" :need-back="true" @on-back="back" />
    <div flex-1>
      <a-tabs v-model:active-key="activeKey">
        <template #rightExtra>
          <a-button type="primary" mr-16px @click="onAddAbility">
            <template #icon>
              <PlusOutlined />
            </template>
            新增
          </a-button>
        </template>
        <a-tab-pane key="1" tab="属性">
          <block-content>
            <AbilityProperty :properties="properties" />
          </block-content>
        </a-tab-pane>
        <a-tab-pane key="2" tab="服务">
          <block-content>
            <AbilityService :services="services" />
          </block-content>
        </a-tab-pane>
        <a-tab-pane key="3" tab="事件">
          <block-content>
            <AbilityEvent :events="events" />
          </block-content>
        </a-tab-pane>
        <a-tab-pane key="4" tab="静态信息">
          <block-content>
            <AbilityProfile :profiles="profiles" />
          </block-content>
        </a-tab-pane>
      </a-tabs>
    </div>
    <AbilityPropertyUpdate v-model:open="modalVisible" :device-ability="undefined" @confirm="onConfirm" />
  </div>
</template>

<style lang="scss" scoped>
@import '@/styles/ant-reset/tabs.scss';
</style>
