import { getDeviceAbilityListApi } from '@/api/device-ability'
import { getDeviceAbilityModelListApi } from '@/api/device-model'
import { getDriverInstancesApi } from '@/api/driver'

const [useProviderAbilityStore, useAbilityStore] = createInjectionState(() => {
  const device = ref<Device>()
  const deviceAbilityModels = ref<any[]>([])
  const driverInstances = ref<any[]>([])
  const deviceAbilities = ref<any[]>([])

  const properties = computed(() => filter('PROPERTY'))
  const services = computed(() => filter('SERVICE'))
  const events = computed(() => filter('EVENT'))
  const profiles = computed(() => filter('PROFILE'))

  function filter(type: any) {
    const res = deviceAbilities.value.filter(ability => ability.abilityType === type)
    return res
  }

  function changeDevice(d: Device | undefined) {
    device.value = d
  }

  onMounted(refreshDriverInstance)

  async function refreshDriverInstance() {
    const instances = await getDriverInstancesApi()
    driverInstances.value = instances.map((instance: any) => instance.driverInstance)
  }

  watch(device, async (newDevice) => {
    if (device.value?.deviceModelCode) {
      await refreshDeviceAbilityModels(device.value.deviceModelCode)
    }
  })

  async function refreshDeviceAbilityies() {
    if (device.value) {
      deviceAbilities.value = await getDeviceAbilityListApi(device.value.deviceCode)
    }
  }

  async function refreshDeviceAbilityModels(deviceModelCode: string) {
    deviceAbilityModels.value = await getDeviceAbilityModelListApi(deviceModelCode)
  }

  return {
    device,
    deviceAbilityModels,
    driverInstances,
    deviceAbilities,
    properties,
    services,
    events,
    profiles,
    changeDevice,
    refreshDriverInstance,
    refreshDeviceAbilityies,
  }
})

export {
  useProviderAbilityStore,
  useAbilityStore,
}
