<script setup lang="ts">
import { Button, Popconfirm, Space, Tag } from 'ant-design-vue'
import UpdateEvent from './update-event.vue'
import { useAbilityStore } from './useState'
import { copyExpressionToDeviceApi, deletePlatformEventApi, queryPlatformEventsApi } from '@/api/event'
import 'vue-json-pretty/lib/styles.css'
import { EventTypeColorMap, EventTypeMap, mapToSelectOptions } from '@/constant/event'
import { getDeviceModelExpressionApi } from '@/api/device-model'

const open = defineModel<boolean>('open')
const updateModalVisible = ref(false)
const expressionVisible = ref(false)
const copyRef = ref()

const { device } = useAbilityStore()!
const message = useMessage()
const searchWords = ref()

function back() {
  open.value = false
}

const expressions = ref<any[]>([])
const choosedEvent = ref()
const expressionData = ref({ eventType: undefined, deviceCode: device.value?.deviceCode })

const state = reactive<TableState>({
  data: [],
  columns: [
    { title: '事件名称', dataIndex: ['eventName'] },
    { title: '事件类型', dataIndex: ['eventType'], customRender({ record }) {
      const eventType = record.eventType
      return h(Tag, { color: EventTypeColorMap.get(eventType) }, () => EventTypeMap.get(eventType))
    } },
    {
      title: '规则表达式名称',
      dataIndex: ['deviceModelExpressionId'],
      customRender({ record }) {
        const expressionId = record.deviceModelExpressionId
        const expression = expressions.value.find(item => item.id === expressionId)
        return expression ? expression.expressionName : ''
      },
    },
    { title: '备注', dataIndex: ['description'] },
    {
      title: '操作',
      customRender({ record }) {
        return h(Space, { direction: 'horizontal', wrap: false }, () => [
          h(Button, { type: 'link', size: 'small', onClick() {
            onEditEvent(record)
          } }, () => '修改'),
          h(
            Popconfirm,
            { title: '确定删除？', onConfirm: () => {
              onDelete(record)
            } },
            () => h(
              Button,
              { danger: true, type: 'link', size: 'small' },
              () => '删除',
            ),
          ),
        ])
      },
    },
  ],
  config: {
    total: 0,
    loading: false,
    isBorder: false,
    isSerialNo: true,
    isSelection: true,
    isOperate: true,
  },
  pagination: false,
})

watch(open, async (newOpen) => {
  if (newOpen) {
    await refresh()
    await getExpressions()
  }
})

async function refresh() {
  state.config.loading = true
  state.data = await queryPlatformEventsApi(device.value.deviceCode)

  state.config.loading = false

  setTimeout(() => {
    state.config.loading = false
  }, 3000)
}

async function getExpressions() {
  expressions.value = await getDeviceModelExpressionApi(device.value.deviceModelCode)
}

function onEditEvent(event: any) {
  updateModalVisible.value = true
  choosedEvent.value = event
}

function onAddEvent() {
  updateModalVisible.value = true
  choosedEvent.value = undefined
}

async function onEventUpdateConfirm() {
  updateModalVisible.value = false
  await refresh()
}

async function onDelete(event: any) {
  const success = await deletePlatformEventApi(event.id)
  if (success) {
    message.success('删除成功')
    await refresh()
  }
  else {
    message.warning('删除失败')
  }
}

async function onCopy() {
  copyRef.value?.validate().then(async () => {
    const success = await copyExpressionToDeviceApi(expressionData.value)
    if (success) {
      message.success('拷贝成功')
      reset()
      await refresh()
    }
  })
}

function reset() {
  expressionVisible.value = false
  copyRef.value.resetFields()
}

function openCopy() {
  expressionVisible.value = true
}

async function onSearch() {
  await refresh()
  state.data = state.data.filter(item => item.eventName.includes(searchWords.value))
}
</script>

<template>
  <div v-if="open" pos-absolute top-0 bottom-0 left-0 right-0 overflow-hidden flex flex-col style="background-color: #f0f2f5;">
    <content-header :title="device?.deviceName ?? ''" :need-back="true" @on-back="back" />

    <flex-content>
      <table-layout>
        <template #headerLeft>
          <a-space>
            <a-button type="primary" @click="onAddEvent">
              新增事件
            </a-button>
            <a-button type="primary" @click="openCopy">
              拷贝表达式
            </a-button>
          </a-space>
        </template>
        <template #headerRight>
          <a-space>
            <a-input v-model:value="searchWords" placeholder="请输入能力名称" allow-clear />
            <a-button type="primary" @click="onSearch">
              搜索
            </a-button>
          </a-space>
        </template>
        <template #content>
          <base-table v-bind="state" />
        </template>
      </table-layout>
    </flex-content>

    <UpdateEvent v-model:open="updateModalVisible" :device-model-code="device.deviceModelCode" :events="state.data" :event="choosedEvent" :device-code="device.deviceCode" @confirm="onEventUpdateConfirm" />

    <a-modal v-model:open="expressionVisible" title="拷贝表达式" @ok="onCopy" @cancel="reset">
      <a-form ref="copyRef" :model="expressionData">
        <a-form-item label="事件类型" name="eventType" required>
          <a-select v-model:value="expressionData.eventType" :options="mapToSelectOptions(EventTypeMap)" />
        </a-form-item>
        该操作将使该设备与其模型上的每一个表达式各绑定生成一个平台事件！
      </a-form>
    </a-modal>
  </div>
</template>
