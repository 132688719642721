<script setup lang="ts">
import { Button, Dropdown, Menu, Popconfirm, Space, type TablePaginationConfig } from 'ant-design-vue'
import { PlusOutlined } from '@ant-design/icons-vue'
import { useAbilityStore, useProviderAbilityStore } from './pages/useState'
import Ability from './pages/ability.vue'
import Events from './pages/events.vue'
import Edit from './pages/edit.vue'
import { getDeviceGroupTreeApi } from '@/api/device-group'
import { getDeviceModelsApi } from '@/api/device-model'
import { getSubSystemsApi } from '@/api/sub-system'
import { deleteDeviceApi, queryDevicesApi } from '@/api/device'

useProviderAbilityStore()

const message = useMessage()
const { changeDevice, device } = useAbilityStore()!
const deviceRuntimeVisible = ref(false)

// 搜索表单配置
const search = ref<TableSearchType[]>([
  { label: '设备名称', name: 'deviceName', placeholder: '请输入设备名称', required: false, type: 'input' },
  { label: '设备编号', name: 'deviceCode', placeholder: '请输入设备编号', required: false, type: 'input' },
])

onMounted(async () => {
  await assignDeviceModelFormItemConfig()
  await assignSubSystemFormItemConfig()
  await assignDeviceGroupFormItemConfig()
})

// 获取子系统form item的配置信息
async function assignSubSystemFormItemConfig() {
  const subSystems = await getSubSystemsApi()
  const options = subSystems.map(subSystem => ({ label: subSystem.subSysName, value: subSystem.id }))
  search.value.push({ label: '子系统', name: 'subSysId', placeholder: '请选择子系统', required: false, type: 'select', options })
}

// 获取空间位置form item配置信息
async function assignDeviceGroupFormItemConfig() {
  const res = await getDeviceGroupTreeApi()
  search.value.push({ label: '空间位置', name: 'deviceGroupIds', placeholder: '请选择空间位置', required: false, type: 'tree-select', options: res })
}

// 获取设备模型form item配置信息
async function assignDeviceModelFormItemConfig() {
  const deviceModels = await getDeviceModelsApi()
  const options = deviceModels.map(({ deviceModelCode, deviceModelName }) => ({ label: deviceModelName, value: deviceModelCode }))
  search.value.push({ label: '产品名称', name: 'deviceModelCode', placeholder: '请选择产品', required: false, type: 'select', options })
}

const state = reactive<TableState>({
  // 列表数据（必传）
  data: [],
  // 表头内容（必传，注意格式）
  columns: [
    { title: '设备名称', dataIndex: 'deviceName' },
    { title: '设备编码', dataIndex: 'deviceCode' },
    { title: '所属产品', dataIndex: 'deviceModelName' },
    { title: '所属子系统', dataIndex: 'subSysName' },
    { title: '空间位置', dataIndex: 'deviceGroupPaths' },
    { title: '描述', dataIndex: 'description' },
    { title: '操作', dataIndex: '', width: 240, customRender: ({ record }) => {
      return h(
        Space,
        { direction: 'horizontal' },
        () => [
          h(
            Button,
            { type: 'link', size: 'small', onClick: () => onViewDevice(record) },
            () => '查看',
          ),
          h(
            Button,
            { type: 'link', size: 'small', onClick: () => onEditDeviceModel(record) },
            () => '编辑',
          ),
          h(
            Popconfirm,
            { title: '确定删除？', onConfirm: () => onDeleteDevice((record as DeviceModel).id) },
            () => h(
              Button,
              { danger: true, type: 'link', size: 'small' },
              () => '删除',
            ),
          ),

          h(
            Dropdown,
            { trigger: 'click', overlay: h(Menu, { items: [
              { key: 1, label: '平台事件', onClick() {
                onEvents(record)
              } },
              { key: 2, label: '设备能力', async onClick() {
                onAbility(record)
              } },
            ],
            }) },
            () => h(Button, { type: 'link', size: 'small' }, () => '更多'),
          ),
        ],
      )
    } },
  ],
  // 配置项（必传）
  config: {
    total: 0, // 列表总数
    loading: false, // loading 加载
    isBorder: false, // 是否显示表格边框
    isSerialNo: true, // 是否显示表格序号
    isSelection: true, // 是否显示表格多选
    isOperate: true, // 是否显示表格操作栏
  },
  pagination: {
    position: ['bottomLeft'],
    pageSize: 20,
    pageSizeOptions: ['20', '30', '40', '50'],
    total: 0,
    current: 1,
    showTotal: total => `共${total}条`,
  },
})

function onViewDevice(device: any) {
  deviceRuntimeVisible.value = true
  changeDevice(device)
}

function onCloseDetail() {
  deviceRuntimeVisible.value = false
}

const editVisible = ref(false)
const eventVisible = ref(false)
const abilityVisible = ref(false)

// 编辑设备模型
function onEditDeviceModel(device: Device) {
  editVisible.value = true
  changeDevice(device)
}

// 查看设备详情
function onEvents(device: Device) {
  eventVisible.value = true
  changeDevice(device)
}

function onAbility(device: Device) {
  abilityVisible.value = true
  changeDevice(device)
}

function onAddDevice() {
  editVisible.value = true
  changeDevice(undefined)
}

// 删除设备
async function onDeleteDevice(deviceId: number) {
  const isDelete = await deleteDeviceApi(deviceId)
  if (isDelete) {
    message.success('删除成功')
    await refreshDevices()
  }
  else {
    message.warning('删除失败')
  }
}

// 翻页
async function onTablePageChange(current: number, pageSize: number) {
  (state.pagination as TablePaginationConfig).current = current;
  (state.pagination as TablePaginationConfig).pageSize = pageSize
  await refreshDevices({ current, size: pageSize })
}

// 刷新页面
async function refreshDevices(queryParams: DeviceQueryParam = { current: 1, size: 20 }) {
  state.config.loading = true
  const res = await queryDevicesApi(queryParams)
  const { records, current, size, total } = res

  state.data = records

  const pagination = state.pagination as TablePaginationConfig
  pagination.current = current
  pagination.pageSize = size
  pagination.total = total

  setTimeout(() => {
    state.config.loading = false
  }, 500)
}

const searchFormData = ref()

// 搜索设备列表
async function onSearch(form: DeviceQueryParam) {
  searchFormData.value = form
  await refreshDevices({ ...form, current: 1, size: (state.pagination as TablePaginationConfig).pageSize ?? 20 })
}

onMounted(refreshDevices)

async function onBackRefresh() {
  const { current, size } = state.pagination as TablePaginationConfig
  await refreshDevices({ current, size, ...searchFormData.value })
}
</script>

<template>
  <page-container>
    <AbsoluteContainer :need-padding="false" :need-bg="false">
      <content-header title="设备管理" />
      <flex-content>
        <div h-full overflow-hidden flex flex-col>
          <div style="background-color: var(--bg-color);" p-12px rounded-4px mb-8px>
            <search-form :search="search" @search="onSearch" />
          </div>
          <div flex-1>
            <flow-hidden-container>
              <table-layout>
                <template #headerLeft>
                  <a-button type="primary" @click="onAddDevice">
                    <template #icon>
                      <PlusOutlined />
                    </template>
                    新增设备
                  </a-button>
                </template>
                <template #content>
                  <base-table v-bind="state" @page-change="onTablePageChange" />
                </template>
              </table-layout>
            </flow-hidden-container>
          </div>
        </div>
      </flex-content>
      <DeviceRuntime :device-code="device?.deviceCode ?? ''" :device-name="device?.deviceName ?? ''" :visible="deviceRuntimeVisible" @close="onCloseDetail" />
      <Edit v-model:open="editVisible" @back="onBackRefresh" />
      <Events v-model:open="eventVisible" />
      <Ability v-model:open="abilityVisible" />
    </AbsoluteContainer>
  </page-container>
</template>
