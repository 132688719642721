<script setup lang="ts">
import type { FormInstance } from 'ant-design-vue'
import { cloneDeep } from 'lodash-es'
import { useAbilityStore } from './useState'
import { addDeviceAbilityApi, updateDeviceAbilityApi } from '@/api/device-ability'
import { getDriverDetailApi } from '@/api/driver'

const props = defineProps(['deviceAbility'])
defineEmits(['confirm'])
const message = useMessage()
const open = defineModel<boolean>('open')
const { device, driverInstances, deviceAbilityModels, profiles, properties, events, services, refreshDeviceAbilityies } = useAbilityStore()!
const formRef = ref<FormInstance>()
const defaultDevieAbility = { abilityCode: '', description: '', abilityType: '', profileValue: undefined, driverInstanceId: undefined, driverModelAbilityIdentifier: undefined, bindRule: {} as any }
const localDeviceAbility = ref(cloneDeep(defaultDevieAbility))
const deviceModelAbilityOptions = ref<any[]>([])
const choosedDeviceModelAbility = ref()
const operationType = ref()
const driverDetail = ref()
const abilities = ref<any[]>([])
const propertyBindRuleModel = ref<any[]>([])
const serviceBindRuleModel = ref()
const choosedDriverDetailAbility = ref()

async function onSubmit() {
  formRef.value?.validate().then(async () => {
    if (localDeviceAbility.value.abilityCode) {
      localDeviceAbility.value.bindRule = JSON.stringify(localDeviceAbility.value.bindRule)
    }

    if (operationType.value === 'update') {
      const isSuccess = await updateDeviceAbilityApi(localDeviceAbility.value)
      if (isSuccess)
        message.success('修改成功')
      open.value = false
      await refreshDeviceAbilityies()
      onCancel()
    }
    if (operationType.value === 'add') {
      const nextProperty = cloneDeep(localDeviceAbility.value)
      nextProperty.deviceName = device.value?.deviceName
      nextProperty.deviceCode = device.value?.deviceCode
      await addDeviceAbilityApi(nextProperty)
      message.success('添加成功')
      open.value = false
      await refreshDeviceAbilityies()
      onCancel()
    }
  })
}

function onCancel() {
  formRef.value?.resetFields()
}

watch(open, (newOpen) => {
  if (newOpen) {
    if (props.deviceAbility) {
      operationType.value = 'update'
      localDeviceAbility.value = cloneDeep(props.deviceAbility)
      initData()
    }
    else {
      operationType.value = 'add'
    }
  }
})

async function initData() {
  resetBindRule(localDeviceAbility.value, false)
  changeDeviceModelAbility()
  if (localDeviceAbility.value.abilityType !== 'PROFILE') {
    await changeDriverInstance()
    const ability = driverDetail.value.abilities.find((item: any) => item.abilityCode === localDeviceAbility.value.driverModelAbilityIdentifier)
    changeAbility(ability, false)
  }
}

function onAbilityTypeChange(type: string) {
  reset()
  changeAbilityType(type)

  function reset() {
    choosedDeviceModelAbility.value = undefined
    const nextDefaultAbility = cloneDeep(defaultDevieAbility)

    resetBindRule(nextDefaultAbility)

    Reflect.deleteProperty(nextDefaultAbility, 'abilityType')
    Object.assign(localDeviceAbility.value, nextDefaultAbility)
    abilities.value = []
    driverDetail.value = undefined
    deviceModelAbilityOptions.value = []
    choosedDriverDetailAbility.value = undefined
  }
}

function resetBindRule(ability: any, initValue: boolean = true) {
  if (localDeviceAbility.value.abilityType === 'SERVICE') {
    serviceBindRuleModel.value = { fixedInputData: [], callerPassedInputDataMap: [], outputDataMap: [] }
    if (initValue)
      ability.bindRule = { fixedInputData: {}, callerPassedInputDataMap: {}, outputDataMap: {} }
  }
  else if (localDeviceAbility.value.abilityType === 'EVENT') {
    serviceBindRuleModel.value = { outputDataKeyValue: [], outputDataMap: [] }
    if (initValue)
      ability.bindRule = { outputDataKeyValue: {}, outputDataMap: {} }
  }
  else {
    if (initValue)
      ability.bindRule = {}
  }
}

function changeAbilityType(type: string) {
  deviceModelAbilityOptions.value = filter(type)

  function filter(type: string) {
    const typeModels = deviceAbilityModels.value.filter(item => item.abilityType === type)
    const deviceCodeSet = new Set()

    switch (type) {
      case 'PROPERTY':{
        properties.value.map(item => deviceCodeSet.add(item.abilityCode))
        break
      }
      case 'PROFILE':{
        profiles.value.map(item => deviceCodeSet.add(item.abilityCode))
        break
      }
      case 'EVENT':{
        events.value.map(item => deviceCodeSet.add(item.abilityCode))
        break
      }
      case 'SERVICE':{
        services.value.map(item => deviceCodeSet.add(item.abilityCode))
        break
      }

      default:
        return []
    }

    return typeModels.filter(model => !(deviceCodeSet.has(model.abilityCode)))
  }
}

function onChangeDeviceModelAbility() {
  reset()
  changeDeviceModelAbility()

  function reset() {
    localDeviceAbility.value.driverInstanceId = undefined
    localDeviceAbility.value.driverModelAbilityIdentifier = undefined
    abilities.value = []
    choosedDriverDetailAbility.value = undefined
  }
}

function changeDeviceModelAbility() {
  choosedDeviceModelAbility.value = deviceAbilityModels.value.find(item => item.abilityCode === localDeviceAbility.value.abilityCode)
  if (localDeviceAbility.value.abilityType === 'SERVICE') {
    serviceBindRuleModel.value.callerPassedInputDataMap = choosedDeviceModelAbility.value?.detail?.inputData ?? []
    serviceBindRuleModel.value.outputDataMap = choosedDeviceModelAbility.value?.detail?.outputData ?? []
  }
  else if (localDeviceAbility.value.abilityType === 'EVENT') {
    serviceBindRuleModel.value.outputDataMap = choosedDeviceModelAbility.value?.detail?.outputData ?? []
  }
}

async function onChangeDriverInstance() {
  reset()
  await changeDriverInstance()

  function reset() {
    localDeviceAbility.value.driverModelAbilityIdentifier = undefined
    if (localDeviceAbility.value.abilityType === 'SERVICE') {
      serviceBindRuleModel.value.fixedInputData = []
      localDeviceAbility.value.bindRule.fixedInputData = {}
    }
    else if (localDeviceAbility.value.abilityType === 'EVENT') {
      serviceBindRuleModel.value.outputDataKeyValue = []
      localDeviceAbility.value.bindRule.outputDataKeyValue = {}
    }
  }
}

async function changeDriverInstance() {
  const driverInstance = driverInstances.value.find(item => item.id === localDeviceAbility.value.driverInstanceId)
  driverDetail.value = await getDriverDetailApi(driverInstance.driverName)
  abilities.value = driverDetail.value.abilities.filter((ability: any) => ability.abilityType === localDeviceAbility.value.abilityType) ?? []
  propertyBindRuleModel.value = driverDetail.value.propertyBindRuleModel ?? []
}

function onChangeAbility(_value: any, record: any) {
  changeAbility(record)
}

function changeAbility(record: any, initData: boolean = true) {
  if (localDeviceAbility.value.abilityType === 'SERVICE' && initData) {
    resetObj(localDeviceAbility.value.bindRule.callerPassedInputDataMap)
    resetObj(localDeviceAbility.value.bindRule.outputDataMap)
    resetObj(localDeviceAbility.value.bindRule.fixedInputData)
  }
  else if (localDeviceAbility.value.abilityType === 'EVENT' && initData) {
    resetObj(localDeviceAbility.value.bindRule.outputDataKeyValue)
    resetObj(localDeviceAbility.value.bindRule.outputDataMap)
  }
  choosedDriverDetailAbility.value = record
  if (localDeviceAbility.value.abilityType === 'SERVICE') {
    serviceBindRuleModel.value.fixedInputData = record.detail?.inputData ?? []
  }
  else if (localDeviceAbility.value.abilityType === 'EVENT') {
    serviceBindRuleModel.value.outputDataKeyValue = record.detail?.outputData ?? []
  }

  function resetObj(obj: any) {
    Object.keys(obj).forEach((key) => {
      obj[key] = null
    })
  }
}

function toOptions(obj: any) {
  return Object.entries(obj).map(([key, value]) => {
    return { label: value, value: key }
  }) as any
}

function getThingModelServices() {
  if (localDeviceAbility.value.abilityType === 'SERVICE') {
    return driverDetail.value.thingModel.services.find((item: any) => item.identifier === choosedDriverDetailAbility.value?.abilityCode) ?? []
  }
  else if (localDeviceAbility.value.abilityType === 'EVENT') {
    return driverDetail.value.thingModel.events.find((item: any) => item.identifier === choosedDriverDetailAbility.value?.abilityCode) ?? []
  }
  return []
}
</script>

<template>
  <a-modal v-model:open="open" :centered="true" width="50%" :title="`${operationType === 'add' ? '新增' : '修改'}`" @ok="onSubmit" @cancel="onCancel">
    <div h-500px>
      <scroll>
        <a-form ref="formRef" :model="localDeviceAbility" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
          <a-form-item v-if="!deviceAbility" label="能力类型" name="abilityType">
            <a-select
              v-model:value="localDeviceAbility.abilityType" :options="[
                { label: '属性', value: 'PROPERTY' },
                { label: '服务', value: 'SERVICE' },
                { label: '事件', value: 'EVENT' },
                { label: '静态信息', value: 'PROFILE' },
              ]"
              @change="onAbilityTypeChange"
            />
          </a-form-item>
          <a-form-item v-if="!deviceAbility && localDeviceAbility.abilityType" has-feedback label="设备能力" name="abilityCode" required>
            <a-select v-model:value="localDeviceAbility.abilityCode" :options="deviceModelAbilityOptions" :field-names="{ label: 'abilityName', value: 'abilityCode' }" @change="onChangeDeviceModelAbility" />
          </a-form-item>

          <template v-if="localDeviceAbility.abilityType === 'PROFILE' && choosedDeviceModelAbility">
            <a-form-item :label="choosedDeviceModelAbility.abilityName" name="profileValue">
              <a-select
                v-if="choosedDeviceModelAbility?.detail?.dataType?.type === 'BOOL'" v-model:value="localDeviceAbility.profileValue" :options="[
                  { label: choosedDeviceModelAbility.detail.dataType.specs.true, value: 'true' },
                  { label: choosedDeviceModelAbility.detail.dataType.specs.false, value: 'false' },
                ]"
              />
              <a-input-number
                v-else-if="choosedDeviceModelAbility?.detail?.dataType?.type === 'INT' || choosedDeviceModelAbility?.detail?.dataType?.type === 'DECIMAL'"
                v-model:value="localDeviceAbility.profileValue"
                :min="choosedDeviceModelAbility.detail.dataType.specs.min ?? -Infinity"
                :max="choosedDeviceModelAbility.detail.dataType.specs.max ?? Infinity"
                :addon-after="choosedDeviceModelAbility.detail.dataType.specs.unit ?? '无单位'"
              />
              <a-date-picker
                v-else-if="choosedDeviceModelAbility?.detail?.dataType?.type === 'DATE'"
                v-model:value="localDeviceAbility.profileValue"
                show-time placeholder="Select Time" format="YYYY-MM-DD HH:mm:ss" value-format="x"
              />

              <a-select
                v-else-if="choosedDeviceModelAbility?.detail?.dataType?.type === 'ENUM'"
                v-model:value="localDeviceAbility.profileValue"
                :options="toOptions(choosedDeviceModelAbility.detail.dataType.specs)"
              />

              <a-input
                v-else-if="choosedDeviceModelAbility?.detail?.dataType?.type === 'TEXT'"
                v-model:value="localDeviceAbility.profileValue"
              />
              <div v-else>
                暂未开放
              </div>
              <!-- <a-select v-model:value="localProperty.abilityCode" :options="deviceModelAbilityOptions" :field-names="{ label: 'abilityName', value: 'abilityCode' }" /> -->
            </a-form-item>
          </template>
          <template v-if="localDeviceAbility.abilityType === 'PROPERTY' || localDeviceAbility.abilityType === 'SERVICE' || localDeviceAbility.abilityType === 'EVENT'">
            <a-form-item label="驱动实例" name="driverInstanceId" required>
              <a-select v-model:value="localDeviceAbility.driverInstanceId" :options="driverInstances" :field-names="{ label: 'driverInstanceName', value: 'id' }" @change="onChangeDriverInstance" />
            </a-form-item>
            <a-form-item v-if="localDeviceAbility.driverInstanceId" label="驱动能力" name="driverModelAbilityIdentifier" required>
              <a-select v-model:value="localDeviceAbility.driverModelAbilityIdentifier" :options="abilities" :field-names="{ label: 'abilityName', value: 'abilityCode' }" @change="onChangeAbility" />
            </a-form-item>

            <!-- 属性---绑点规则 -->
            <a-form-item v-if="localDeviceAbility.abilityType === 'PROPERTY'" label="绑点规则">
              <a-table
                :data-source="propertyBindRuleModel" :columns="[
                  { title: '参数名称', dataIndex: 'name' },
                  { title: '参数编号', dataIndex: 'identifier' },
                  { title: '数值', dataIndex: 'data' },
                ]"
                :pagination="false"
                :scroll="{ y: 250 }"
              >
                <template #bodyCell="{ column, record }">
                  <template v-if="column.dataIndex === 'data'">
                    <a-form-item :name="['bindRule', record.identifier]" :required="record.required">
                      <a-input v-if="record?.dataType?.type === 'TEXT'" v-model:value="localDeviceAbility.bindRule[record.identifier]" />
                      <a-select
                        v-else-if="record?.dataType?.type === 'BOOL'"
                        v-model:value="localDeviceAbility.bindRule[record.identifier]" :options="[
                          { label: record.dataType.specs.true, value: 'true' },
                          { label: record.dataType.specs.false, value: 'false' },
                        ]"
                      />
                      <a-select v-else-if="record?.dataType?.type === 'ENUM'" v-model:value="localDeviceAbility.bindRule[record.identifier]" :options="toOptions(record.dataType.specs)" />
                      <a-input-number
                        v-else-if="record?.dataType?.type === 'INT' || record?.dataType?.type === 'DECIMAL'"
                        v-model:value="localDeviceAbility.bindRule[record.identifier]"
                        :min="record.dataType.specs.min ?? -Infinity"
                        :max="record.dataType.specs.max ?? Infinity"
                        :addon-after="record.dataType.specs.unit ?? '无单位'"
                      />
                      <a-date-picker
                        v-else-if="record?.dataType?.type === 'DATE'"
                        v-model:value="localDeviceAbility.bindRule[record.identifier]"
                        show-time format="YYYY-MM-DD HH:mm:ss" value-format="x"
                      />
                      <div v-else>
                        暂未开放
                      </div>
                    </a-form-item>
                  </template>
                </template>
              </a-table>
            </a-form-item>

            <template v-if="driverDetail && localDeviceAbility.abilityType === 'SERVICE' ">
              <!-- 服务---驱动层固定入参 -->
              <a-form-item v-if="serviceBindRuleModel && serviceBindRuleModel.fixedInputData.length > 0 && choosedDriverDetailAbility && localDeviceAbility.bindRule && localDeviceAbility.bindRule.fixedInputData" label="驱动层固定入参">
                <a-table
                  :data-source="serviceBindRuleModel.fixedInputData" :columns="[
                    { title: '入参名称', dataIndex: 'name' },
                    { title: '入参编号', dataIndex: 'identifier' },
                    { title: '数值', dataIndex: 'data' },
                  ]"
                  :pagination="false"
                  :scroll="{ y: 250 }"
                >
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.dataIndex === 'data'">
                      <a-input v-if="record?.dataType?.type === 'TEXT'" v-model:value="localDeviceAbility.bindRule.fixedInputData[record.identifier]" />
                      <a-select
                        v-else-if="record?.dataType?.type === 'BOOL'"
                        v-model:value="localDeviceAbility.bindRule.fixedInputData[record.identifier]" :options="[
                          { label: record.dataType.specs.true, value: 'true' },
                          { label: record.dataType.specs.false, value: 'false' },
                        ]"
                      />
                      <a-select v-else-if="record?.dataType?.type === 'ENUM'" v-model:value="localDeviceAbility.bindRule.fixedInputData[record.identifier]" :options="toOptions(record.dataType.specs)" />
                      <a-input-number
                        v-else-if="record?.dataType?.type === 'INT' || record?.dataType?.type === 'DECIMAL'"
                        v-model:value="localDeviceAbility.bindRule.fixedInputData[record.identifier]"
                        :min="record.dataType.specs.min ?? -Infinity"
                        :max="record.dataType.specs.max ?? Infinity"
                        :addon-after="record.dataType.specs.unit ?? '无单位'"
                      />
                      <a-date-picker
                        v-else-if="record?.dataType?.type === 'DATE'"
                        v-model:value="localDeviceAbility.bindRule.fixedInputData[record.identifier]"
                        show-time format="YYYY-MM-DD HH:mm:ss" value-format="x"
                      />
                      <div v-else>
                        暂未开放
                      </div>
                    </template>
                  </template>
                </a-table>
              </a-form-item>
              <!-- 服务---入参映射 -->
              <a-form-item v-if="serviceBindRuleModel && serviceBindRuleModel.callerPassedInputDataMap.length > 0 && choosedDriverDetailAbility && localDeviceAbility.bindRule && localDeviceAbility.bindRule.callerPassedInputDataMap" label="入参映射">
                <a-table
                  :data-source="serviceBindRuleModel.callerPassedInputDataMap" :columns="[
                    { title: '入参名称', dataIndex: 'name' },
                    { title: '入参编号', dataIndex: 'identifier' },
                    { title: '数值', dataIndex: 'data' },
                  ]"
                  :pagination="false"
                  :scroll="{ y: 250 }"
                >
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.dataIndex === 'data'">
                      <a-select v-model:value="localDeviceAbility.bindRule.callerPassedInputDataMap[record.identifier]" :options="getThingModelServices().inputData" :field-names="{ label: 'name', value: 'identifier' }" />
                    </template>
                  </template>
                </a-table>
              </a-form-item>
              <!-- 服务---出参映射 -->
              <a-form-item v-if="serviceBindRuleModel && serviceBindRuleModel.outputDataMap.length > 0 && choosedDriverDetailAbility && localDeviceAbility.bindRule && localDeviceAbility.bindRule.outputDataMap" label="出参映射">
                <a-table
                  :data-source="serviceBindRuleModel.outputDataMap" :columns="[
                    { title: '入参名称', dataIndex: 'name' },
                    { title: '入参编号', dataIndex: 'identifier' },
                    { title: '数值', dataIndex: 'data' },
                  ]"
                  :pagination="false"
                  :scroll="{ y: 250 }"
                >
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.dataIndex === 'data'">
                      <a-select v-model:value="localDeviceAbility.bindRule.outputDataMap[record.identifier]" :options="getThingModelServices().outputData" :field-names="{ label: 'name', value: 'identifier' }" />
                    </template>
                  </template>
                </a-table>
              </a-form-item>
            </template>
            <template v-if="driverDetail && localDeviceAbility.abilityType === 'EVENT' ">
              <!-- 事件---绑定条件 -->
              <a-form-item v-if="serviceBindRuleModel && serviceBindRuleModel.outputDataKeyValue.length > 0 && choosedDriverDetailAbility" label="绑定条件">
                <a-table
                  :data-source="serviceBindRuleModel.outputDataKeyValue" :columns="[
                    { title: '入参名称', dataIndex: 'name' },
                    { title: '入参编号', dataIndex: 'identifier' },
                    { title: '数值', dataIndex: 'data' },
                  ]"
                  :pagination="false"
                  :scroll="{ y: 250 }"
                >
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.dataIndex === 'data'">
                      <a-input v-model:value="localDeviceAbility.bindRule.outputDataKeyValue[record.identifier]" />
                    </template>
                  </template>
                </a-table>
              </a-form-item>
              <!-- 事件---出参映射 -->
              <a-form-item v-if="serviceBindRuleModel && serviceBindRuleModel.outputDataMap.length > 0 && choosedDriverDetailAbility" label="出参映射">
                <a-table
                  :data-source="serviceBindRuleModel.outputDataMap" :columns="[
                    { title: '入参名称', dataIndex: 'name' },
                    { title: '入参编号', dataIndex: 'identifier' },
                    { title: '数值', dataIndex: 'data' },
                  ]"
                  :pagination="false"
                  :scroll="{ y: 250 }"
                >
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.dataIndex === 'data'">
                      <a-select v-model:value="localDeviceAbility.bindRule.outputDataMap[record.identifier]" :options="getThingModelServices().outputData" :field-names="{ label: 'name', value: 'identifier' }" />
                    </template>
                  </template>
                </a-table>
              </a-form-item>
            </template>
          </template>

          <a-form-item label="备注" name="description">
            <a-input v-model:value="localDeviceAbility.description" />
          </a-form-item>
        </a-form>
      </scroll>
    </div>
  </a-modal>
</template>
