<script setup lang="ts">
import type { FormInstance } from 'ant-design-vue'
import { cloneDeep } from 'lodash-es'
import { EventTypeMap, mapToSelectOptions } from '@/constant/event'
import { getDeviceModelExpressionApi } from '@/api/device-model'
import { addPlatformEventApi, updatePlatformEventApi } from '@/api/event'

const props = defineProps(['events', 'deviceModelCode', 'event', 'deviceCode'])
const emit = defineEmits(['confirm'])
const formRef = ref<FormInstance>()
const open = defineModel<boolean>('open')
const localEvent = ref({ eventName: '', eventType: '', deviceModelExpressionId: undefined, description: '', deviceCode: '' })
const expressions = ref<any[]>([])
function onSubmit() {
  formRef.value?.validate().then(async () => {
    let success
    if (props.event == null) {
      localEvent.value.deviceCode = props.deviceCode
      success = await addPlatformEventApi(localEvent.value)
    }
    else {
      success = await updatePlatformEventApi(localEvent.value)
    }

    if (success)
      emit('confirm')
  })
}

watch(open, async (isOpen) => {
  if (isOpen) {
    initEvent()
    await initExpressionOptions()
  }
})

function initEvent() {
  if (props.event != null)
    localEvent.value = cloneDeep(props.event)
  else
    localEvent.value = { eventName: '', eventType: '', deviceModelExpressionId: undefined, description: '', deviceCode: '' }
}

async function initExpressionOptions() {
  const res = await getDeviceModelExpressionApi(props.deviceModelCode)

  expressions.value = res.filter((item: any) => {
    const condition1 = props.events.findIndex((e: any) => e.deviceModelExpressionId === item.id) === -1
    const condition2 = item.id === props.event?.deviceModelExpressionId
    return condition1 || condition2
  })
}

function onCancel() {
  formRef.value?.resetFields()
}
</script>

<template>
  <a-modal v-model:open="open" :title="`${event == null ? '新增' : '修改'}事件`" :centered="true" @ok="onSubmit" @cancel="onCancel">
    <a-form ref="formRef" :model="localEvent" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
      <a-form-item label="事件名称" name="eventName" required>
        <a-input v-model:value="localEvent.eventName" placeholder="请输入事件名称" />
      </a-form-item>
      <a-form-item label="事件类型" name="eventType" required>
        <a-select v-model:value="localEvent.eventType" :options="mapToSelectOptions(EventTypeMap)" />
      </a-form-item>
      <a-form-item label="表达式" name="deviceModelExpressionId" required>
        <a-select v-model:value="localEvent.deviceModelExpressionId" :options="expressions" :field-names="{ label: 'expressionName', value: 'id' }" />
      </a-form-item>
      <a-form-item label="备注" name="description">
        <a-input v-model:value="localEvent.description" placeholder="请输入" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
