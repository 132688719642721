<script setup lang="ts">
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons-vue'
import { useAbilityStore } from './useState'
import { addDeviceApi, getDeviceByDeviceCodeApi, updateDeviceApi } from '@/api/device'
import { isDirty } from '@/utils/tools'
import { addDeviceGroupRelationsApi, deleteDeviceGroupRelationsApi, getDeviceGroupTreeApi } from '@/api/device-group'
import { getDriverInstancesApi } from '@/api/driver'
import { bindVideoToDeviceApi, getVideoByDeviceCodeApi } from '@/api/video'
import { getDeviceModelsApi } from '@/api/device-model'

const emit = defineEmits<{ back: [] }>()
const open = defineModel<boolean>('open')

const deviceRef = ref()
const videoRef = ref()
const positionSearchValue = ref('')
const loading = ref(false)
// 视频
const video = ref({ cloudPlatformSupport: [], channels: [] as Channel[] } as any)
// 空间位置树结构数据
const deviceGroupTree = ref<Tree<DeviceGroupTreeNode>[]>([])
const driverInstances = ref<any[]>([])
const cacheDeviceGroupId = ref()
const deviceModels = ref<any[]>([])

const localDevice = ref<Partial<Device>>({
  deviceName: '',
  deviceCode: '',
  deviceModelName: '',
  deviceGroupId: -1,
  description: '',
})
const { device } = useAbilityStore()!

const { findAllParents, forEachTree } = useTree(deviceGroupTree)

const videoActiveKey = ref('col')
const colAndWrap = ref({
  labelCol: { span: 4 },
  wrapperCol: { span: 21 },
})

watch(open, async (newOpen) => {
  if (newOpen) {
    await getDeviceGroupTree()
    await getDeviceModels()
    await getDevice()
    // 选择节点，并展示数据
    await getDriverInstances()
    await initVideo()
  }
})

async function getDeviceModels() {
  deviceModels.value = await getDeviceModelsApi()
}

async function initVideo() {
  if (device.value == null)
    return
  const res: any = await getVideoByDeviceCodeApi(localDevice.value.deviceCode as string)
  res.channels = (res.channels ?? []).map((channel: any) => ({ name: channel.name, url: channel.url, id: Date.now() }))
  // cloudPlatformSupport

  const keys = ['directionEnabled', 'focusEnabled', 'apertureEnabled', 'zoomEnabled']

  res.cloudPlatformSupport = keys.filter((key: any) => res[key] === true)
  video.value = res
}

// 获取设备信息
async function getDevice() {
  if (isDirty(device.value)) {
    return
  }
  localDevice.value = await getDeviceByDeviceCodeApi(device.value.deviceCode)
  cacheDeviceGroupId.value = localDevice.value.deviceGroupId
}

async function getDeviceGroupTree() {
  deviceGroupTree.value = await getDeviceGroupTreeApi()
  forEachTree((node) => {
    const parentNodes = findAllParents(node.id)
    const labels = parentNodes.map(node => node.label).join(' / ')
    node.value = labels
  })
}

async function getDriverInstances() {
  const res = await getDriverInstancesApi()
  driverInstances.value = res.map((item: any) => (item.driverInstance))
}

function onBack() {
  open.value = false
}

function addChannel() {
  video.value.channels.push({ url: '', name: '', id: Date.now() })
}

function removeChannel(index: number) {
  video.value.channels.splice(index, 1)
}

const videoRes = ref(false)
const deviceRes = ref(false)

function submit() {
  loading.value = true
  submitDevice()
}

function submitDevice() {
  deviceRef.value.validate().then(async () => {
    if (device.value == null) {
      await addDeviceApi(localDevice.value)
    }
    else {
      await updateDeviceApi(localDevice.value)
    }
    await bindDeviceGroupRelation()
    deviceRes.value = true
    submitVideo()
  }).catch(() => {
    loading.value = false
  })
}

function submitVideo() {
  videoRef.value.validate().then(async () => {
    video.value.id = localDevice.value.deviceCode as any
    video.value.cloudPlatformSupport.forEach((key: any) => {
      video.value[key] = true
    })
    await bindVideoToDeviceApi({ ...video.value })
    videoRes.value = true
  }).catch(() => {
    loading.value = false
  })
}

watch([videoRes, deviceRes], ([videoNew, deviceNew]) => {
  if (videoNew && deviceNew) {
    loading.value = false
    videoRes.value = false
    deviceRes.value = false
    onBack()
    emit('back')
  }
})

async function bindDeviceGroupRelation() {
  if (cacheDeviceGroupId.value != null) {
    await deleteDeviceGroupRelationsApi({ deviceCodes: [localDevice.value.deviceCode], deviceGroupId: cacheDeviceGroupId.value })
  }

  if (localDevice.value.deviceGroupId != null) {
    await addDeviceGroupRelationsApi({ deviceCodes: [localDevice.value.deviceCode], deviceGroupId: localDevice.value.deviceGroupId })
  }
}

function filterOption(input: string, option: any) {
  return option.deviceModelName.toLowerCase().includes(input.toLowerCase())
}
function filterDriverInstance(input: string, option: any) {
  return option.driverInstanceName.toLowerCase().includes(input.toLowerCase())
}
</script>

<template>
  <div v-if="open" pos-absolute top-0 bottom-0 left-0 right-0 overflow-hidden flex flex-col style="background-color: #f0f2f5;">
    <content-header :title="`${device == null ? '新增' : '编辑'}设备`" :need-back="true" @on-back="onBack" />
    <scroll mt-20px>
      <a-row justify="center">
        <a-col :span="14">
          <!-- 设备信息 -->
          <a-form
            ref="deviceRef" :model="localDevice" :colon="false" :label-col="{ span: 3 }"
            :wrapper-col="{ span: 21 }"
          >
            <a-form-item label="产品名称" name="deviceModelCode" required>
              <a-select
                v-model:value="localDevice.deviceModelCode" placeholder="请选择产品" :options="deviceModels"
                :field-names="{ label: 'deviceModelName', value: 'deviceModelCode' }"
                show-search :filter-option="filterOption"
                :disabled="device != null"
              />
            </a-form-item>
            <a-form-item label="设备名称" name="deviceName" required>
              <a-input v-model:value="localDevice.deviceName" />
            </a-form-item>
            <a-form-item label="设备编号" name="deviceCode" required>
              <a-input v-model:value="localDevice.deviceCode" :disabled="device != null" />
            </a-form-item>
            <a-form-item label="空间位置" name="deviceGroupPaths">
              <a-tree-select
                v-if="deviceGroupTree && deviceGroupTree.length > 0"
                v-model:value="localDevice.deviceGroupId"
                v-model:search-value="positionSearchValue" :tree-data="deviceGroupTree"
                tree-node-filter-prop="label"
                :field-names="{ children: 'children', value: 'id', label: 'label' }"
                placeholder="请选择"
                tree-default-expand-all show-search
                allow-clear
              >
                <template #title="{ value: val, label }">
                  <template v-if="label">
                    <template
                      v-for="(fragment, i) in label
                        .toString()
                        .split(new RegExp(`(?<=${positionSearchValue})|(?=${positionSearchValue})`, 'i'))"
                    >
                      <span
                        v-if="fragment.toLowerCase() === positionSearchValue.toLowerCase()"
                        :key="i"
                        style="color: #08c"
                      >
                        {{ fragment }}
                      </span>
                      <template v-else>
                        {{ fragment }}
                      </template>
                    </template>
                  </template>
                </template>
              </a-tree-select>
            </a-form-item>
            <a-form-item label="备注" name="description">
              <a-textarea v-model:value="localDevice.description" :rows="4" />
            </a-form-item>
          </a-form>

          <!-- 视频信息 -->
          <a-collapse v-model:active-key="videoActiveKey" ghost>
            <a-collapse-panel key="col" header="视频设备">
              <a-form v-bind="colAndWrap" ref="videoRef" :model="video" :colon="false">
                <a-form-item label="声音支持">
                  <a-switch v-model:checked="video.audioEnabled" checked-children="支持" un-checked-children="不支持" />
                </a-form-item>
                <a-form-item label="云台支持">
                  <a-checkbox-group v-model:value="video.cloudPlatformSupport">
                    <a-checkbox value="directionEnabled" name="type">
                      方向
                    </a-checkbox>
                    <a-checkbox value="zoomEnabled" name="type">
                      焦距
                    </a-checkbox>
                    <a-checkbox value="focusEnabled" name="type">
                      焦点
                    </a-checkbox>
                    <a-checkbox value="apertureEnabled" name="type">
                      光圈
                    </a-checkbox>
                  </a-checkbox-group>
                </a-form-item>

                <a-form-item label="驱动实例">
                  <a-select
                    v-model:value="video.driverInstanceId" placeholder="请选择驱动实例" :options="driverInstances"
                    :field-names="{ label: 'driverInstanceName', value: 'id' }"
                    allow-clear show-search :filter-option="filterDriverInstance"
                  />
                </a-form-item>
                <a-form-item label="子系统厂商标识">
                  <a-input v-model:value="video.videoProviderResourceId" />
                </a-form-item>
                <a-form-item v-if="video.channels.length > 0" label="通道">
                  <a-space
                    v-for="(channel, index) in video.channels" :key="channel.id"
                    style="display: flex; margin-bottom: 8px" align="baseline" block
                  >
                    <a-form-item
                      :name="['channels', index, 'name']" :rules="{
                        required: true,
                        message: '请填写通道名称',
                      }"
                    >
                      <a-input v-model:value="video.channels[index].name" placeholder="通道名称" />
                    </a-form-item>
                    <a-form-item
                      :name="['channels', index, 'url']" :rules="{
                        required: true,
                        message: '请填写视频通道地址',
                      }" flex-1
                    >
                      <a-input v-model:value="video.channels[index].url" placeholder="视频通道地址" />
                    </a-form-item>

                    <MinusCircleOutlined @click="removeChannel(index)" />
                  </a-space>
                </a-form-item>
                <a-form-item label=" ">
                  <a-button type="dashed" block @click="addChannel">
                    <PlusOutlined />
                    添加视频通道
                  </a-button>
                </a-form-item>
              </a-form>
            </a-collapse-panel>
          </a-collapse>
        </a-col>
      </a-row>
      <div h-60px />
    </scroll>
    <FooterToolBar>
      <template #right>
        <a-button type="primary" @click="submit">
          提交
        </a-button>
      </template>
    </FooterToolBar>
  </div>
</template>

<style lang="scss">
.custom-spin-container {
  height: 100%;
  overflow: hidden;

  &>div>.ant-spin{
    max-height: 100% !important;
  }

  .ant-spin-container {
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
}
</style>

<style lang="scss" scoped>
:deep(.ant-space) {
  .ant-space-item:nth-child(2) {
    flex: 1
  }
}
</style>
